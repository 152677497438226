@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
//Bootstrap Optional
//@import "bootstrap/scss/root";
//@import "bootstrap/scss/reboot";
//@import "bootstrap/scss/type";
//@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/grid";
//@import "bootstrap/scss/tables";
//@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
//@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
//@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/print";

//Bootstrap Optional
@import "BootstrapCustom/images";
@import "BootstrapCustom/transitions";
@import "BootstrapCustom/list-group";
@import "BootstrapCustom/modal";

//Custom
@import "mixins";

// Custom Styles
@import "textpic";
@import "teaser";
@import "texticon";
@import "gallery";
@import "magnific";
@import "uploads";
@import "cards";
@import "navbar";
@import "slider";
@import "forms";
@import "infoteaser";

@each $color, $value in $theme-colors {
  .swatch-#{$color} {
    color: color-yiq($value);
    background-color: $value;
  }
}

// Skiplinks
.skiplinks {
  .sr-only {
    &:focus {
      display: inline-block;
      padding: 0.5rem;
      width: auto;
      height: auto;
      overflow: visible;
      clip: auto;
      white-space: normal;
      background-color: $white;
      z-index: $zindex-tooltip;
    }
  }
}

// Layout
.container {
  padding-left: 25px;
  padding-right: 25px;

  @include media-breakpoint-up(md) {
    width: 90%;
  }

  @include media-breakpoint-up(lg) {
    width: 80%;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

// wave under headlines
.style-wave {
  &::after {
    content: '';
    background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Icons/welle.svg");
    width: 58px;
    height: 30px;
    display: block;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 10px 0 20px;
  }

  &.text-center {
    display: block;

    &::after {
      margin: 10px auto 20px;
    }
  }
}

// List styling
main {
  ul {
    list-style-type: none;
    padding-left: 25px;

    li {
      position: relative;
      padding: 7px 0;
      color: $black;
      font-size: 1.375rem;
      line-height: 1.3;

      &:first-child {
        &:not(.splide__slide) {
          padding-top: 0;
        }
      }

      &:not(.splide__slide) {
        &::before {
          content: '';
          background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Freigold_Logo_i-nochbreiter.svg");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          left: -25px;
          width: 7px;
          height: 20px;
          margin-top: 7px;
        }
      }

      &::marker {
        display: none;
      }
    }
  }
}


// Footer
.footer {
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-content {
    color: $white;
    background-color: $black;
    font-size: 1.0625rem;
    padding: 60px 0;

    a {
      color: $white;
    }

    @include media-breakpoint-up(md) {
      padding: 80px 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.0625rem;
      text-transform: none;
      padding: 110px 0;
    }

    .footer-logo {
      img {
        margin: 0 auto;
        display: block;
        max-width: 100px;
        width: 100%;

        @include media-breakpoint-up(lg) {
          max-width: 155px;
        }

        @include media-breakpoint-up(sm) {
          margin: 0 auto 0 0;
        }
      }
    }

    .footer-address {
      //padding: 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      //@include media-breakpoint-up(sm) {
      //  padding: 0 0 10px;
      //}

      address {
        p {
          text-align: center;
          color: $white;
          font-size: 1.2rem;

          @include media-breakpoint-up(sm) {
            text-align: left;
            font-size: $font-size-base;
          }
        }
      }

      .social {
        margin-top: 20px;

        @include media-breakpoint-up(sm) {
          margin-left: 0;
          margin-right: 0;
        }

        ul {
          display: flex;
          justify-content: center;

          @include media-breakpoint-up(sm) {
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }

        .list-inline-item:not(:last-child) {
          margin-right: 2rem;
          margin-bottom: 1rem;

          @include media-breakpoint-up(lg) {
            margin-right: 1rem;
          }

          @include media-breakpoint-up(xl) {
            margin-right: 2rem;
          }
        }

        a, svg {
          width: 20px;
          height: 20px;
          display: block;
        }

      }

    }

    .footer-auszeichnungen {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        margin: 3rem 1rem 0;

        img {
          display: block;
          height: 70px;
        }
      }

      @include media-breakpoint-up(md) {

        .item {
          margin-left: 0;
          margin-right: 2rem;
        }
      }

      @include media-breakpoint-up(lg) {
        flex-direction: column;

        .item {
          margin: 0 0 1rem 0;
        }
      }

      @media (min-width: 1600px) {
        flex-direction: row;
        align-items: flex-start;

        .item {
          img {
            height: 90px;
          }
        }
      }
    }

    .footer-meta-navigation {
      div {
        height: 100%;

        nav {
          height: 100%;

          .list-inline {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;

            .list-inline-item {
              margin: 0;
              text-transform: uppercase;
              font-size: 1.2rem;
              line-height: 1.6;
            }

            @include media-breakpoint-up(sm) {
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: center;

              .list-inline-item {
                margin: 0 10px;
                font-size: 1.5rem;
              }
            }

            @include media-breakpoint-up(md) {
              flex-direction: column;
              flex-wrap: wrap;
              justify-content: flex-start;
              padding: 0 0 10px;

              .list-inline-item {
                margin: 0;
                line-height: 1.3;
                padding-bottom: 7px;
              }
            }
          }
        }
      }

      @include media-breakpoint-up(sm) {
        margin-top: 60px;
      }

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

    }
  }

  &-bottom {
    background-color: $lightgray;
    font-size: 1rem;

    p {
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
    }

    .list-inline {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    .list-inline-item {
      margin-bottom: 10px;
      font-size: 1.1rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }

      a {
        color: $dark;
      }
    }

    .list-inline-item:not(:last-child) {
      margin-right: 15px;

      @include media-breakpoint-up(lg) {
        margin-right: 25px;
      }

      @include media-breakpoint-up(xl) {
        margin-right: 40px;
      }
    }
  }
}


//
// Scrolltop
//
.scroll-top {
  opacity: 0;
  z-index: 1050;
  background-color: $primary;
  color: $white;
  bottom: -1px;
  left: -1px;
  position: fixed;
  display: none;
  transform: translateX(-100vw);
  transition: all ($transition-time * 3) $transition-ease;
  font-size: initial;

  a {
    text-decoration: none;
  }

  @media (min-width: 1600px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: $headings-font-family;

    .langswitcher {
      height: 51px;
      justify-content: center;
      align-items: center;
      margin: 0 !important;

      .language-menu {
        .langnav-item {
          &:not(.current) {
            width: 0;
            opacity: 0;
          }

          a {
            font-size: 1.1875rem;
            text-transform: lowercase;
            display: flex;
            color: $white;

            span {
              width: 0;
              opacity: 0;
              margin: 0;
            }
          }
        }
      }
    }

    .mobile-icon-buttons {
      flex-direction: column;
      justify-content: space-between;
      padding: 15px 12px;

      .w4-mobile-menu-link,
      .w4-booking-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 0;
        animation: slideInLeft 400ms ease-in-out forwards;

        .icon {
          fill: $white;
          transform: scale(1);
          transition: transform $transition-time $transition-ease;
        }

        .text {
          font-size: 1.1875rem;
          margin-top: 10px;
          text-transform: none;
          line-height: 1;
          color: $white;
          font-weight: 400;
          font-family: $font-family-base;
        }

        &:hover,
        &:focus {
          .icon {
            transform: scale(1.1);
            transition: transform $transition-time $transition-ease;
          }

          .text {
            text-decoration: none;
          }
        }
      }

      .w4-booking-link {
        margin: 0;

        .icon {
          display: block;
        }
      }

      .w4-phone-link {
        animation-delay: 200ms;
      }

      .w4-voucher-link {
        animation-delay: 400ms;
        margin: 0 !important;
      }

      .w4-faq-link {
        animation-delay: 600ms;
      }

      .w4-mobile-menu-link {
        .text {
          display: block;
        }
      }

      .line {
        background-color: $white;
        opacity: 40%;
        height: 1px;
        width: 70px;
        display: block;
        animation: fadeIn 1.5s ease-in-out forwards;
        margin: 20px auto;
      }
    }
  }

  .scroll-top-link {
    overflow: hidden;
    width: 3em;
    height: 3em;
    display: block;
    position: relative;
    color: $white;
    transform: scale(1);
    transition: transform $transition-time $transition-ease;

    &:active,
    &:focus,
    &:hover {
      transform: scale(1.1);
      transition: transform $transition-time $transition-ease;
    }

    .scroll-top-icon {
      @extend .css-arrow;
    }
  }

  &.scroll-top-visible {
    transform: translateX(0);
    opacity: 1;
    transition: all ($transition-time * 3) $transition-ease;
  }
}

.css-arrow {
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    height: 30%;
    width: 30%;
    border-top: 1px solid;
    border-left: 1px solid;
    top: 55%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
}

.banner {
  position: relative;

  .image {
    margin: 0;

    img {
      filter: brightness(0.7);
    }
  }

  .image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .element-header {
      display: block;
      color: $white;
    }

    .banner-text {
      font-weight: $font-weight-normal;

      .btn-primary {
        font-weight: $font-weight-normal;
      }
    }

    p {
      color: $white;
    }
  }
}

/* Tabellen auf Seite "Kosmetik & Masagen - ID 207" anpassen, dass die zweite Spalte mit den Preisen gleich breit ist */

.page-207 {
  table {
    tbody {
      tr {
        td:nth-of-type(2) {
          width: 20%;
        }
      }
    }
  }
}

.sub-header {
  font-size: 1.4rem;
  display: block;

  @include media-breakpoint-up(md) {
    font-size: 1.6rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

h1.element-header {
  color: $gold;
}
