.infoteaser-items-wrapper {
    position: fixed;
    z-index: 1010;
    display: flex;
    align-items: flex-end;
    pointer-events: none;

    &.show-it {
        .infoteaser-inner {
            opacity: 1;
            transform: translateX(0);
            transition: all ($transition-time * 3) $transition-ease;
        }
    }

    &.hide-it {
        .infoteaser-inner {
            opacity: 0;
            transform: translateX(100vw);
            transition: all ($transition-time * 3) $transition-ease;
        }
    }

    .infoteaser-inner {
        display: flex;
        position: relative;
        bottom: 51px;
        padding: 2rem;
        width: 271px;
        min-height: 271px;
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
        background-color: black;
        pointer-events: auto;

        .infoteaser-item {
            p {
                font-family: "Barlow Condensed", sans-serif;
                font-size: 19px;
                font-weight: 400;
                color: white;
            }

            a {
                font-size: 19px;
            }

            .infoteaser-title {
                font-size: 26px;
                font-weight: 500;
                margin-bottom: 10px;
            }
        }
    }

    @media (min-width: 0) {
        left: auto;
        right: 25px;
        bottom: 25px;
        margin-left: 20px;
    }

    @media (min-width: 992px) {
        right: 40px;
        bottom: 40px;
    }

    @media (min-width: 1600px) {
        .infoteaser-inner {
            bottom: unset;
        }
    }
}

.infoteaser-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 26px;
    height: 26px;
    display: block;
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
    margin-top: 0;

    &:focus {
        box-shadow: unset;
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 0;
        height: 26px;
        width: 1px;
        background-color: white;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}
