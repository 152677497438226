.img-fluid {
  max-width: 100%;
  height: auto;
}

/*
.img-thumbnail {
  padding: $thumbnail-padding;
  background-color: $thumbnail-bg;
  border: $thumbnail-border-width solid $thumbnail-border-color;
  @include border-radius($thumbnail-border-radius);
  @include box-shadow($thumbnail-box-shadow);

  // Keep them at most 100% wide
  @include img-fluid();
}
*/
.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 5px;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #EDEDED;
}
.list-group-item-action:active {
  color: #4D4F4D;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #C5A563;
  border-color: #C5A563;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #665633;
  background-color: #efe6d3;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #665633;
  background-color: #e8dbc0;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #665633;
  border-color: #665633;
}

.list-group-item-secondary {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #850000;
  background-color: #ffb8b8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #850000;
  background-color: #ff9f9f;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #850000;
  border-color: #850000;
}

.list-group-item-light {
  color: #7b7b7b;
  background-color: #fafafa;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #7b7b7b;
  background-color: #ededed;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #7b7b7b;
  border-color: #7b7b7b;
}

.list-group-item-dark {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #949490;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.3;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #949490;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.textmedia,
.textpic {
  display: flex;
  flex-wrap: wrap;
}

.textmedia-item,
.textpic-item {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.textmedia-item figure,
.textpic-item figure {
  margin-bottom: 0;
}
.textmedia-item figure .embed,
.textpic-item figure .embed {
  display: flex;
}
.textmedia-item video, .textmedia-item img,
.textpic-item video,
.textpic-item img {
  width: 100%;
}

.textpic-item {
  order: 1;
}

.textmedia-text > *:last-child,
.textpic-text > *:last-child {
  margin-bottom: 0;
}

.textmedia-gallery,
.textpic-gallery {
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .textmedia-gallery,
.textpic-gallery {
    margin-bottom: 0;
  }
}

.textpic-left .textpic-gallery {
  order: 2;
}
@media (min-width: 768px) {
  .textpic-left .textpic-gallery {
    order: 1;
  }
}

.textmedia-below .textmedia-text,
.textmedia-below .textpic-text,
.textpic-below .textmedia-text,
.textpic-below .textpic-text {
  margin-bottom: 10px;
}
.textmedia-below .textmedia-gallery,
.textmedia-below .textpic-gallery,
.textpic-below .textmedia-gallery,
.textpic-below .textpic-gallery {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .textmedia-right .textmedia-item,
.textmedia-right .textpic-item,
.textmedia-left .textmedia-item,
.textmedia-left .textpic-item,
.textpic-right .textmedia-item,
.textpic-right .textpic-item,
.textpic-left .textmedia-item,
.textpic-left .textpic-item {
    margin-bottom: 0;
    width: 50%;
  }
}
.frame-type-textpic .figure-caption {
  display: none;
}
.frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-text {
  padding: 40px 0;
}
@media (min-width: 1200px) {
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-text {
    padding: 100px 0;
  }
}
.frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery {
  margin-bottom: 0;
}
.frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .image {
  margin-right: calc(-50vw + 50%);
  margin-left: calc(-50vw + 50%);
  margin-bottom: 0;
}
.frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .image .figure-img {
  width: 100%;
}
@media (min-width: 768px) {
  .frame-type-textpic:not(.frame-traunreiter) .textpic {
    justify-content: space-between;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic.textpic-right .image {
    margin-left: 0;
    margin-right: calc(-50vw + 100%);
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic.textpic-left .image {
    margin-right: 0;
    margin-left: calc(-50vw + 100%);
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-text {
    max-width: 520px;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery {
    display: flex;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .gallery-row {
    flex-grow: 1;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .gallery-row .gallery-item {
    display: flex;
    flex-grow: 1;
    padding: 0;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .gallery-row .gallery-item .image {
    flex-grow: 1;
    display: flex;
    margin-bottom: 0;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .gallery-row .gallery-item .image picture {
    position: relative;
    flex-grow: 1;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .gallery-row .gallery-item .image picture .figure-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .frame-type-textpic:not(.frame-traunreiter) .textpic .textpic-gallery .figure-caption {
    display: none;
  }
}

.textmedia-stoerer-left,
.textmedia-stoerer-right {
  position: relative;
}
.textmedia-stoerer-left .textmedia-gallery,
.textmedia-stoerer-right .textmedia-gallery {
  justify-content: flex-start;
}
@media (max-width: 991.98px) {
  .textmedia-stoerer-left figure.image img,
.textmedia-stoerer-right figure.image img {
    margin-top: 50px;
    max-width: 220px;
  }
}

.textmedia-stoerer-left:after {
  content: "";
  position: absolute;
  top: 7%;
  bottom: -10%;
  width: 110%;
  background-color: #EDEDED;
  z-index: -1;
}
@media (min-width: 768px) {
  .textmedia-stoerer-left:after {
    width: 150%;
    top: 7%;
    bottom: -10%;
    left: 0;
    right: initial;
  }
}

.textmedia-stoerer-right:after {
  content: "";
  position: absolute;
  top: 7%;
  bottom: -10%;
  left: -25px;
  right: -25px;
  width: 110%;
  background-color: #EDEDED;
  z-index: -1;
}
@media (min-width: 768px) {
  .textmedia-stoerer-right:after {
    width: 150%;
    top: 7%;
    bottom: -10%;
    right: 0;
    left: initial;
  }
}

.textmedia-offgrid-layout {
  position: relative;
}
@media (min-width: 768px) {
  .textmedia-offgrid-layout .header-title {
    position: absolute;
    left: -30px;
    bottom: 25%;
    transform: rotate(-90deg);
    transform-origin: left;
    z-index: 1000;
  }
}
@media (min-width: 992px) {
  .textmedia-offgrid-layout .header-title {
    left: 0;
  }
}

.textmedia-offgrid-layout-left .textmedia-gallery {
  justify-content: flex-start;
}
.textmedia-offgrid-layout-left .header-title {
  top: 0;
  left: 0;
  transform-origin: left;
}

.textpic-offgrid {
  position: relative;
}
.textpic-offgrid::after {
  display: none;
  position: absolute;
  content: "";
  background-color: #C5A563;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 50%;
  z-index: -1;
}
@media (min-width: 576px) {
  .textpic-offgrid::after {
    display: initial;
  }
}
@media (min-width: 992px) {
  .textpic-offgrid::after {
    height: 100%;
  }
}
.textpic-offgrid::before {
  display: none;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  z-index: 0;
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern.svg");
  background-repeat: repeat;
  background-size: contain;
  clip-path: inset(0 50% 0 0);
}
@media (min-width: 576px) {
  .textpic-offgrid::before {
    display: initial;
  }
}
@media (min-width: 992px) {
  .textpic-offgrid::before {
    height: 100%;
  }
}
.textpic-offgrid .header-title {
  position: absolute;
  bottom: 0;
  z-index: 1000;
  top: 55%;
}
.textpic-offgrid .header-title .element-header {
  color: white;
  text-align: center;
}
.textpic-offgrid .gallery-item {
  padding-top: 40px;
}
@media (min-width: 576px) {
  .textpic-offgrid .gallery-item {
    padding-top: 0;
  }
}
.textpic-offgrid figure.image {
  position: relative;
}
.textpic-offgrid figure.image::after {
  display: initial;
  position: absolute;
  content: "";
  background-color: #C5A563;
  top: -40px;
  width: 80%;
  height: calc(100% + 80px);
  z-index: -2;
}
@media (min-width: 576px) {
  .textpic-offgrid figure.image::after {
    display: none;
  }
}
.textpic-offgrid figure.image::before {
  display: initial;
  position: absolute;
  content: "";
  top: -40px;
  width: 80%;
  height: calc(100% + 80px);
  z-index: -1;
  left: initial;
  right: -25px;
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern.svg");
  background-repeat: repeat;
  background-size: contain;
}
@media (min-width: 576px) {
  .textpic-offgrid figure.image::before {
    display: none;
  }
}
.textpic-offgrid figure.image picture {
  background: black;
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .textpic-offgrid figure.image picture {
    margin-bottom: 0;
  }
}
.textpic-offgrid figure.image img {
  opacity: 0.8;
  margin: 0;
  width: 100%;
  height: auto;
}
.textpic-offgrid.textpic-offgrid-text-right {
  left: 0;
}
.textpic-offgrid.textpic-offgrid-text-right .header-title {
  left: 0;
  transform: translateX(25%) translateY(-50%);
}
@media (min-width: 992px) {
  .textpic-offgrid.textpic-offgrid-text-right .header-title {
    transform: translateX(-33%) translateY(-50%);
  }
}
@media (min-width: 1200px) {
  .textpic-offgrid.textpic-offgrid-text-right .header-title {
    transform: translateX(-50%) translateY(-50%);
  }
}
.textpic-offgrid.textpic-offgrid-text-right figure.image::after {
  left: -25px;
}
.textpic-offgrid.textpic-offgrid-text-right figure.image::before {
  left: -25px;
  right: initial;
}
.textpic-offgrid.textpic-offgrid-text-right figure.image {
  padding-right: 50px;
}
.textpic-offgrid.textpic-offgrid-text-left .header-title {
  right: 0;
  transform: translateX(-25%) translateY(-50%);
}
@media (min-width: 992px) {
  .textpic-offgrid.textpic-offgrid-text-left .header-title {
    transform: translateX(33%) translateY(-50%);
  }
}
@media (min-width: 1200px) {
  .textpic-offgrid.textpic-offgrid-text-left .header-title {
    transform: translateX(50%) translateY(-50%);
  }
}
.textpic-offgrid.textpic-offgrid-text-left::after, .textpic-offgrid.textpic-offgrid-text-left::before {
  right: 0;
}
.textpic-offgrid.textpic-offgrid-text-left::before {
  clip-path: inset(0 0 0 50%);
}
.textpic-offgrid.textpic-offgrid-text-left figure.image::after {
  right: -25px;
}
.textpic-offgrid.textpic-offgrid-text-left figure.image {
  padding-left: 50px;
}
.textpic-offgrid .textpic-offgrid-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

@media (max-width: 767.98px) {
  .textpic-offgrid.frame.frame-space-after-large,
.textpic-offgrid.frame.frame-space-before-large {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.frame-textmedia-offgrid-layout::after {
  position: absolute;
  content: "";
  background-color: white;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 20%;
  z-index: 1;
}
.frame-textmedia-offgrid-layout .textmedia-item {
  position: relative;
  z-index: 2;
}

.teaser {
  position: relative;
}
.teaser a {
  text-decoration: none;
}
.teaser-row .teaser {
  margin-bottom: 10px;
}
@media (max-width: 767.98px) {
  .teaser-row .teaser {
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 575.98px) {
  .teaser-row .teaser {
    max-width: 294px;
  }
}
@media (min-width: 992px) {
  .teaser-row .teaser {
    margin-bottom: 20px;
  }
}
.teaser .teaser-title {
  position: absolute;
  left: 0;
  z-index: 100;
  transform-origin: left;
  bottom: 40px;
  color: #000000;
  white-space: nowrap;
  font-size: 2rem;
  font-family: "Barlow Condensed", sans-serif, sans-serif;
}
@media (min-width: 768px) {
  .teaser .teaser-title {
    left: -10px;
  }
}

.teaser-top {
  position: relative;
  height: 0;
  overflow: hidden;
  color: #fff;
  padding-bottom: 150%;
}
.teaser-top {
  font-size: 1.375rem;
}
@media (min-width: 576px) {
  .teaser-top {
    font-size: calc(1.375rem + 1.625 * ((100vw - 36rem) / 39));
  }
}
@media (min-width: 1200px) {
  .teaser-top {
    font-size: 3rem;
  }
}
.teaser-top:hover picture img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.teaser-top img {
  filter: brightness(0.7);
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.teaser-top picture::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0) 100%);
}
.teaser-nav .teaser-top {
  padding-bottom: 75%;
}
.teaser-nav .teaser-top .teaser-title {
  font-size: 1.375rem;
}
.teaser-nav .teaser-top .teaser-title .teaser-arrow {
  top: -3px;
}
.teaser-top .img-fluid {
  min-width: 100%;
}
.teaser-top .teaser-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  line-height: 1.1;
  padding: 1.75rem 1rem;
  color: #fff;
  font-weight: 200;
  margin-bottom: 0;
}
@media (min-width: 1200px) {
  .teaser-top .teaser-title {
    padding: 1.75rem 1.5rem;
  }
}
.teaser-top .teaser-title .teaser-arrow {
  position: relative;
  white-space: nowrap;
  top: -7px;
  font-size: 1.25rem;
}

.teaser-bottom {
  margin-top: 0.875rem;
  padding: 0 1rem;
  color: #000000;
  margin-bottom: 20px;
}
@media (min-width: 1200px) {
  .teaser-bottom {
    padding: 0 1.5rem;
  }
}
.teaser-nav .teaser-bottom {
  color: #000000;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .teaser-nav .teaser-title .teaser-arrow {
    font-size: 0.9375rem;
  }
  .teaser-nav .teaser-subtitle {
    font-size: 1rem;
  }
}
.teaser-splide {
  padding-top: 50px;
  padding-bottom: 50px;
}
.teaser-splide .splide__arrows {
  padding-top: 100px;
}

.teaser-splide .splide__list .splide__slide:nth-of-type(2n) {
  transform: translateY(50px);
}

.teaser-splide .splide__list .splide__slide:nth-of-type(3n) {
  transform: translateY(25px);
}

.frame-background-black .splide__arrow svg,
.frame-background-bg-image .splide__arrow svg {
  filter: brightness(0) invert(1);
}

.dropdown-menu-content .teaser .teaser-title,
.frame-background-black .teaser .teaser-title,
.frame-background-bg-image .teaser .teaser-title {
  color: #fff;
}

.texticon::after {
  display: block;
  clear: both;
  content: "";
}
.texticon-above .texticon-icon {
  text-align: center;
  margin-bottom: 1rem;
}
.texticon-left .texticon-icon, .texticon-right .texticon-icon {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}
.texticon-left .texticon-content, .texticon-right .texticon-content {
  overflow: hidden;
  zoom: 1;
}
.texticon-left .texticon-content *:first-child, .texticon-right .texticon-content *:first-child {
  margin-top: 0;
}
.texticon-left .texticon-content *:last-child, .texticon-right .texticon-content *:last-child {
  margin-bottom: 0;
}
.texticon-left .texticon-icon {
  float: left;
  margin-left: -5px;
}
.texticon-right .texticon-icon {
  float: right;
  margin-right: -5px;
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.gallery-item {
  padding-left: 5px;
  padding-right: 5px;
}

figure.image {
  margin-bottom: 10px;
}
figure.image img,
figure.image picture {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}
figure.image a picture:hover img {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
figure.image picture {
  overflow: hidden;
}
figure.image img {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

.gallery-item-size-1 {
  width: 100%;
}

.gallery-item-size-2 {
  width: calc(100% / 2);
}

.gallery-item-size-3 {
  width: calc(100% / 3);
}

.gallery-item-size-4 {
  width: calc(100% / 2);
}
@media (min-width: 768px) {
  .gallery-item-size-4 {
    width: calc(100% / 4);
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }

  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }

  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }

  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.media-list {
  padding-left: 0;
  list-style: none;
}
.media-list .media + .media {
  margin-top: 0.5rem;
}

.media-left {
  padding-right: 0.5rem;
}
.media-heading {
  display: flex;
  align-items: center;
}
.media-heading > a {
  display: inline-flex;
  align-items: center;
}

.uploads-fileicon {
  margin-right: 0.25rem;
}
.uploads-fileicon svg {
  width: 1em;
  height: 1em;
}
.uploads-filesize {
  margin-left: 0.25rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 5px;
    margin-bottom: 0;
    margin-left: 5px;
  }
}

.card-group > .card {
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card-img-top {
  overflow: hidden;
}
.card-img-top picture,
.card-img-top img {
  width: 100%;
  height: auto;
}

.card-body > *:first-child {
  margin-top: 0;
}
.card-body > *:last-child {
  margin-bottom: 0;
}

.accordion-item.card {
  border: none;
}

.accordion-item {
  margin-top: 1rem;
}

.accordion-header {
  padding: 0;
  background-color: #EDEDED;
  border: none;
}
.accordion-header .btn-link {
  position: relative;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  border: none;
  box-shadow: none;
  white-space: normal;
  text-transform: none;
  text-decoration: none;
  margin-bottom: 0;
}
.accordion-header .btn-link:hover, .accordion-header .btn-link:focus {
  text-decoration: none;
  color: #000000;
}
.accordion-header .accordion-header-icon {
  position: absolute;
  height: 10px;
  width: 10px;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
}
.accordion-header .accordion-header-icon:before, .accordion-header .accordion-header-icon:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;
}
.accordion-header .accordion-header-icon:before {
  transform: rotate(45deg);
}
.accordion-header .accordion-header-icon:after {
  transform: rotate(-45deg);
  left: -7px;
}
.accordion-header .btn-link.collapsed .accordion-header-icon:before {
  transform: rotate(-45deg);
}
.accordion-header .btn-link.collapsed .accordion-header-icon:after {
  transform: rotate(45deg);
}

.accordion-content {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}

.accordion-content-item {
  flex-grow: 1;
  order: 1;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
}

.accordion-content-text *:last-child {
  margin-bottom: 0;
}

.accordion-body {
  border-top: none;
  background-color: #EDEDED;
  padding: 10px 15px;
  color: #000000;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-item,
.accordion-content-left .accordion-content-item {
    width: 50%;
  }
  .accordion-content-right .accordion-content-item *:last-child,
.accordion-content-left .accordion-content-item *:last-child {
    margin-bottom: 0;
  }
}

.accordion-content-top .accordion-content-gallery,
.accordion-content-left .accordion-content-gallery {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .accordion-content-left .accordion-content-gallery {
    margin-bottom: 0;
  }
}

.accordion-content-bottom .accordion-content-gallery,
.accordion-content-right .accordion-content-gallery {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .accordion-content-right .accordion-content-media {
    margin-top: 0;
  }
}

.card-group-element {
  margin: -5px;
  margin-bottom: -5px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 5px;
}
.card-group-element-item .card {
  height: 100%;
}
.card-group-element-item .card-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.card-group-element-item .card-subtitle {
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}
.card-group-element-item .card-title + .card-subtitle {
  margin-top: -10px;
}
.card-group-element-item .card-footer {
  border-top: 0;
  padding: 10px;
  background-color: transparent;
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1200px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 4);
  }
}

html.main-navbar-open {
  overflow: hidden;
}

.menu-open .navbar .navbar-wrapper {
  visibility: visible;
  transform: translateY(0);
  top: 0;
  transition: all 0.3s ease-in-out;
}
.menu-open .navbar .navbar-wrapper .nav-bg-image {
  display: block;
  z-index: -1;
}
@media (min-width: 992px) {
  .menu-open .navbar .navbar-wrapper .nav-bg-image {
    display: none;
  }
}

.navbar .navbar-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  background-color: #000;
  visibility: hidden;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1030;
}
@media (min-width: 992px) {
  .navbar .navbar-wrapper {
    visibility: visible;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    height: auto;
    padding: 0 20px;
  }
}
.navbar .navbar-wrapper .nav-bg-image {
  display: none;
  position: absolute;
  width: 233px;
  right: -50px;
  bottom: 60px;
  opacity: 0.1;
}
@media (min-width: 992px) {
  .navbar .navbar-wrapper .nav-bg-image {
    display: none;
  }
}
.navbar .navbar-collapse {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 0 0;
  height: calc(100vh - 126px);
  z-index: 1;
  color: #000;
}
.navbar .navbar-collapse .navbar-nav {
  display: block;
}
@media (min-width: 992px) {
  .navbar .navbar-collapse {
    padding: 0;
    height: 95px;
  }
}
.navbar .navbar-nav .nav-link,
.navbar .navbar-nav .dropdown-title {
  font-size: 1.75rem;
  line-height: 1;
  padding: 10px 0;
}
.navbar .navbar-nav .nav-link {
  color: #fff;
}
.navbar .navbar-nav .nav-item {
  display: flex;
  justify-content: center;
}
.navbar .navbar-nav .dropdown {
  position: static;
}
@media (min-width: 992px) {
  .navbar .navbar-nav .dropdown.show:after {
    content: "";
    position: absolute;
    bottom: -18px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 30px 20px;
    border-color: transparent transparent #ffffff transparent;
    animation: dropdown-fade 250ms ease-out forwards;
  }
}
.navbar .navbar-nav .dropdown .dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 40px 0;
  overflow-x: hidden;
  background-color: #000000;
  color: white;
}
.navbar .navbar-nav .dropdown .dropdown-menu .dropdown-title {
  display: flex;
  flex-direction: column;
}
.navbar .navbar-nav .dropdown .dropdown-menu .dropdown-title .dropdown-title-image {
  margin-top: 10px;
  display: block;
  width: 38px;
  height: 21px;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .nav-link,
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item .dropdown-item-image {
  display: none;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item .nav-link {
  font-size: 1.438rem;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-menu-item .nav-link:hover {
  color: #000000;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-close .dropdown-item {
  font-size: 1.063rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .navbar-nav .dropdown .dropdown-menu ul .dropdown-close .dropdown-item .back-icon {
  fill: #fff;
  margin-right: 10px;
}
@media (min-width: 992px) {
  .navbar.navbar-expand-lg .navbar-collapse {
    background: #000;
  }
  .navbar.navbar-expand-lg .navbar-nav {
    align-items: flex-end;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu {
    left: 50%;
    top: 100px;
    right: unset;
    bottom: unset;
    transform: translate(-50%, 0);
    padding: 0 30px;
    background: #000000;
    border: 0;
    width: 100vw;
    height: auto;
    position: fixed;
    overflow-y: visible;
    overflow-x: visible;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu ul {
    width: auto;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close {
    align-self: flex-end;
  }
}
@media (min-width: 992px) and (min-width: 1700px) {
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close {
    position: relative;
  }
}
@media (min-width: 992px) {
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn {
    display: flex;
    align-items: center;
    line-height: 1;
    margin: 20px 0;
    padding: 0;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .text {
    position: relative;
    color: white;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .text::after {
    content: "";
    position: absolute;
    bottom: -5px;
    height: 2px;
    left: 0;
    right: 100%;
    background-color: #000000;
    transition: right 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .close-icon {
    display: block;
    width: 26px;
    height: 26px;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .close-icon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    opacity: 1;
    left: 0;
    background-color: #fff;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    top: 13px;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .close-icon span:nth-child(1) {
    transform: rotate(45deg);
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn .close-icon span:nth-child(2) {
    transform: rotate(-45deg);
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:hover, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:focus, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:active {
    background-color: transparent;
    color: #000000;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:hover .text::after, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:focus .text::after, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-close .dropdown-close-btn:active .text::after {
    right: 0;
    transition: right 0.3s ease-in-out;
  }
}
@media (min-width: 992px) {
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show {
    animation: dropdown-fade 250ms ease-out forwards;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 1312px;
    padding-bottom: 40px;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .layout-mega-menu .teaser-title {
    bottom: 0;
    transform: rotate(-90deg);
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item {
    display: inline-block;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    color: white;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link::after {
    display: none;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link .dropdown-item-image {
    display: block;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.nav-link .dropdown-item-image .figure-img {
    margin: 0;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a .teaser-title {
    font-size: 1.6rem;
    text-transform: initial;
    line-height: 1;
    background-color: unset;
    margin: 0 15px;
    display: inline-flex;
    white-space: normal;
    text-align: left;
    /*
    &::before {
           content: '';
           position: absolute;
           bottom: 0;
           height: 2px;
           left: 0;
           right: 100%;
           background-color: $dark;
           transition: right $transition-time $transition-ease;
       } */
    /*
    &.white {
        color: $white;

        &::before {
            background: $white;
        }
    }*/
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a .teaser-title.dark {
    color: #000000;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a .teaser-title.dark::before {
    background: #000000;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:hover, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:focus, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:active, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.active {
    background-color: unset;
    color: #fff;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:hover .dropdown-item-image, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:focus .dropdown-item-image, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:active .dropdown-item-image, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.active .dropdown-item-image {
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:hover .teaser-title::before, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:focus .teaser-title::before, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a:active .teaser-title::before, .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .dropdown-menu-item a.active .teaser-title::before {
    right: 0;
    transition: right 0.3s ease-in-out;
  }
  .navbar.navbar-expand-lg .navbar-nav .dropdown-menu.show .teaser {
    max-width: 250px;
    margin-left: 50px;
  }
}
@media (min-width: 992px) {
  .navbar .nav-item .fa-chevron-down {
    position: relative;
    top: -1px;
    margin-left: 0.5rem;
  }
}

@keyframes dropdown-fade {
  from {
    opacity: 0;
    transform: translate(calc(-50% + 20px), -1rem);
  }
  to {
    opacity: 1;
    transform: translate(calc(-50% + 20px), 0);
  }
}
/*IE 11 Browserhack to fix Submenu background issue*/
@media all and (-ms-high-contrast: none) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    min-width: 600px;
  }
}
/* Splide Core Files */
@keyframes splide-loading {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.splide {
  position: relative;
  visibility: hidden;
  outline: none;
}

.splide__container {
  position: relative;
  box-sizing: border-box;
}

.splide__list {
  margin: 0 !important;
  padding: 0 !important;
  width: max-content;
  will-change: transform;
}
.splide.is-active .splide__list {
  display: flex;
}

.splide__pagination {
  display: inline-flex;
  align-items: center;
  width: 95%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}
.splide__pagination li {
  list-style-type: none;
  display: inline-block;
  line-height: 1;
  margin: 0;
}
.splide__slide {
  position: relative;
  box-sizing: border-box;
  list-style-type: none !important;
  margin: 0;
  outline: none;
  flex-shrink: 0;
}
.splide__slide img {
  vertical-align: bottom;
}

.splide__slider {
  position: relative;
}

.splide__track {
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.splide--draggable > .splide__track > .splide__list > .splide__slide {
  user-select: none;
}

.splide--fade > .splide__track > .splide__list {
  display: block;
}
.splide--fade > .splide__track > .splide__list > .splide__slide {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  opacity: 0;
}
.splide--fade > .splide__track > .splide__list > .splide__slide.is-active {
  position: relative;
  z-index: 1;
  opacity: 1;
}

/* Splide Default Style Files */
.splide--nav > .splide__track > .splide__list > .splide__slide {
  border: 3px solid transparent;
}
.splide--nav > .splide__track > .splide__list > .splide__slide.is-active {
  border-color: black;
}
.splide--nav > .splide__track > .splide__list > .splide__slide:focus {
  outline: none;
}

.type-wvierteaser-teaserslider {
  overflow: hidden;
}
.type-wvierteaser-teaserslider .element-header {
  margin-bottom: 20px;
}
.type-wvierteaser-teaserslider .splide__track {
  overflow: unset;
}

.splide {
  visibility: unset;
}
.splide__slide:after {
  display: none;
}
.splide__track {
  overflow: visible;
}
.splide__track .image {
  overflow: hidden;
}
.splide__track .image img {
  width: 100%;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.splide__track .image .figure-caption {
  display: none;
}
.splide__track .image:hover img, .splide__track .image:focus img {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}
.splide__arrow {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: 0;
  padding: 0;
  background: transparent;
  transition: fill 0.3s ease-in-out;
}
.splide__arrow svg {
  width: 25px;
  height: 25px;
}
@media (min-width: 992px) {
  .splide__arrow svg {
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 992px) {
  .splide__arrow {
    width: 40px;
    height: 40px;
  }
}
.splide__arrow--next {
  transform: rotate(180deg);
}
.splide__arrow[disabled] {
  fill: #EDEDED;
}
.splide__arrow:hover {
  cursor: pointer;
}
.splide__arrow:hover:not(:disabled) {
  fill: #C5A563;
}
.splide__arrow:focus {
  outline: none;
}

.image-splide.mfp-gallery .splide__slide.is-active + .splide__slide {
  transform: translateY(50px);
}
@media (min-width: 768px) {
  .image-splide.mfp-gallery .splide__slide.is-active + .splide__slide {
    transform: translateY(75px);
  }
}
@media (min-width: 992px) {
  .image-splide.mfp-gallery .splide__slide.is-active + .splide__slide {
    transform: translateY(100px);
  }
}
.image-splide.mfp-gallery .splide__slide {
  transition: all 0.3s ease-in-out;
}
.image-splide.mfp-gallery .splide__arrows {
  padding-top: 100px;
}

.frame-type-wvier_imageslider {
  position: relative;
}
.frame-type-wvier_imageslider::after {
  position: absolute;
  content: "";
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern-gallery.svg");
  background-size: contain;
  background-repeat: no-repeat;
  left: 25%;
  top: 25%;
  height: 80%;
  width: 50%;
  z-index: -1;
}
@media (min-width: 576px) {
  .frame-type-wvier_imageslider::after {
    max-width: 520px;
    left: 33%;
    top: 10%;
    bottom: -10%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.13158;
  color: #000000;
  background-color: rgba(237, 237, 237, 0.5);
  background-clip: padding-box;
  border: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out;
}
.frame-background-light .form-control {
  background-color: rgba(0, 0, 0, 0.08);
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
@media (min-width: 992px) {
  .form-control {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}
.form-control:focus {
  color: #000000;
  background-color: rgba(148, 148, 144, 0.5);
  outline: 0;
  box-shadow: none;
}
.form-control::placeholder {
  color: #4D4F4D;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #949490;
  opacity: 1;
}
.form-control:disabled {
  cursor: not-allowed;
}
.form-control[type=file] {
  padding-top: 1.25rem;
}
@media (min-width: 992px) {
  .form-control[type=file] {
    padding-top: 1.375rem;
  }
}

textarea.form-control {
  height: auto;
  min-height: 8rem;
  resize: vertical;
}

.form-group {
  margin-bottom: 1rem;
}

.form-set {
  margin-bottom: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 8px;
  padding-left: 8px;
}

/*.valid-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
}

@include form-validation-state-selector($state) {
    ~ .valid-feedback,
    ~ .valid-tooltip {
        display: block;
    }
}*/
.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23C5A563' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}
@media (min-width: 992px) {
  .was-validated .form-control:valid, .form-control.is-valid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}
@media (min-width: 992px) {
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23C5A563' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'%3e%3c/path%3e%3c/svg%3e") rgba(237, 237, 237, 0.5) no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:valid, .custom-control-input.is-valid {
  /*            &:checked {
                  ~ .custom-control-label::before {
                      border-color: lighten($color, 10%);
                      @include gradient-bg(lighten($color, 10%));
                  }
              }

              &:focus {
                  ~ .custom-control-label::before {
                      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                  }

                  &:not(:checked) ~ .custom-control-label::before {
                      border-color: $color;
                  }
              }*/
}

/*.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
}

@include form-validation-state-selector($state) {
    ~ .invalid-feedback,
    ~ .invalid-tooltip {
        display: block;
    }
}*/
.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23ff0000' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 1rem;
}
@media (min-width: 992px) {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    padding-right: 2rem;
    background-position: right 1rem center;
  }
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 1.5rem;
  background-position: top 1.375rem right 0.75rem;
}
@media (min-width: 992px) {
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: 2rem;
    background-position: top 1.5rem right 1rem;
  }
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3e%3cpath fill='%23ff0000' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z'%3e%3c/path%3e%3c/svg%3e") rgba(237, 237, 237, 0.5) no-repeat center right 1.75rem/calc(0.56579em + 0.375rem) calc(0.56579em + 0.375rem);
}

.was-validated .custom-control-input:invalid, .custom-control-input.is-invalid {
  /*            &:checked {
                  ~ .custom-control-label::before {
                      border-color: lighten($color, 10%);
                      @include gradient-bg(lighten($color, 10%));
                  }
              }

              &:focus {
                  ~ .custom-control-label::before {
                      box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
                  }

                  &:not(:checked) ~ .custom-control-label::before {
                      border-color: $color;
                  }
              }*/
}
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #ff0000;
}

label {
  font-size: 1.125rem;
  line-height: 1.13158;
}

.form-group-floating {
  position: relative;
}
.form-group-floating label {
  position: absolute;
  left: 0.75rem;
  top: 1.375rem;
  color: #4D4F4D;
  transition: all 0.15s ease-in-out;
}
@media (min-width: 992px) {
  .form-group-floating label {
    left: 1rem;
    top: 1.5rem;
  }
}
.form-group-floating input:focus ~ label,
.form-group-floating textarea:focus ~ label, .form-group-floating.input-filled > label {
  font-size: 0.625rem;
  top: 0.25rem;
  color: #000000;
}
@media (min-width: 992px) {
  .form-group-floating input:focus ~ label,
.form-group-floating textarea:focus ~ label, .form-group-floating.input-filled > label {
    top: 0.5rem;
  }
}

.custom-control {
  position: relative;
  z-index: 1;
}
.custom-control .custom-control-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}
.custom-control .custom-control-label {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 2.5rem;
}
.custom-control .custom-control-label::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  margin-top: -0.75rem;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  background-color: rgba(0, 0, 0, 0.08);
}
.frame-background-dark .custom-control .custom-control-label::before {
  background-color: rgba(237, 237, 237, 0.5);
}
.custom-control .custom-control-label::after {
  position: absolute;
  display: none;
  content: "";
  top: 50%;
  margin-top: -6px;
}
.custom-control:hover .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.12);
}
.frame-background-dark .custom-control:hover .custom-control-label::before {
  background-color: rgba(148, 148, 144, 0.5);
}
.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: rgba(0, 0, 0, 0.12);
}
.frame-background-dark .custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: rgba(148, 148, 144, 0.5);
}
.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #C5A563;
}
.frame-background-dark .custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #C5A563;
}
.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  display: block;
}
.custom-control.custom-control-checkbox .custom-control-label::before {
  border-radius: 0;
}
.custom-control.custom-control-checkbox .custom-control-label::after {
  width: 6px;
  height: 12px;
  left: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.custom-control.custom-control-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-control.custom-control-radio .custom-control-label::after {
  width: 12px;
  height: 12px;
  left: 6px;
  background-color: #fff;
  border-radius: 50%;
}

label.form-group-label {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.custom-control a {
  text-decoration: underline;
}
.custom-control.font-xs .custom-control-label {
  font-size: 1.125rem;
  line-height: 1.3;
}
.custom-control.custom-control-block {
  width: 100%;
  padding-left: 0;
  margin-bottom: 1rem;
}
.custom-control.custom-control-block .custom-control-label {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 3.75658rem;
  height: 3.75658rem;
  background-color: rgba(237, 237, 237, 0.5);
  padding: 0.75rem 0.75rem 0.75rem 3.25rem;
  border-radius: 0;
}
@media (min-width: 992px) {
  .custom-control.custom-control-block .custom-control-label {
    min-height: 4.00658rem;
    height: 4.00658rem;
  }
}
.custom-control.custom-control-block .custom-control-label:hover {
  background-color: rgba(148, 148, 144, 0.5);
}
.custom-control.custom-control-block .custom-control-label::before {
  left: 0.75rem;
}
.custom-control.custom-control-block.custom-control-checkbox .custom-control-label::after {
  left: 1.3125rem;
}
.custom-control.custom-control-block.custom-control-radio .custom-control-label::after {
  left: 1.125rem;
}
.custom-control.custom-control-block .custom-control-input:checked ~ .custom-control-label {
  background-color: #C5A563;
  color: #fff;
}
.custom-control.custom-control-block .custom-control-input:focus:not(:checked) ~ .custom-control-label {
  background-color: rgba(148, 148, 144, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.75658rem;
  padding: 1.375rem 0.75rem 1.25rem;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 1.125rem;
  font-weight: 200;
  line-height: 1.13158;
  color: #000000;
  vertical-align: middle;
  background: rgba(237, 237, 237, 0.5) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%234D4F4D' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 0;
  border-radius: 0;
  appearance: none;
}
@media (min-width: 992px) {
  .custom-select {
    padding: 1.5rem 1rem 1.375rem;
    height: 4.00658rem;
  }
}
.custom-select:focus {
  color: #000000;
  background-color: rgba(148, 148, 144, 0.5);
  outline: 0;
  box-shadow: none;
}
.custom-select:focus::-ms-value {
  color: #000000;
  background-color: rgba(237, 237, 237, 0.5);
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000000;
}

.form-group-select {
  position: relative;
}
.form-group-select label {
  position: absolute;
  left: 0.75rem;
  top: 0.25rem;
  color: #000000;
  font-size: 0.625rem;
}
@media (min-width: 992px) {
  .form-group-select label {
    left: 1rem;
    top: 0.5rem;
  }
}

.help-block {
  font-size: 80%;
  line-height: 1.3;
  display: inline-block;
  color: red;
}

.form-ce p {
  line-height: 1.3;
}

/**** CASABLANCA Styling ****/
.casa-wdg {
  font-family: unset;
  font-size: 1.5rem;
}
.casa-wdg .casa-form-group label,
.casa-wdg .form-group label {
  font-weight: 200;
}
@media (min-width: 992px) {
  .casa-wdg .casa-form-group label,
.casa-wdg .form-group label {
    text-align: right;
  }
}
.casa-wdg .casa-form-group.casa-has-error,
.casa-wdg .form-group.casa-has-error {
  color: #a94442;
}
.casa-wdg .casa-form-group.casa-has-error .casa-form-control,
.casa-wdg .form-group.casa-has-error .casa-form-control {
  border-color: #a94442;
}
.casa-wdg .form-control,
.casa-wdg .casa-form-control,
.casa-wdg .k-widget,
.casa-wdg .k-dropdown {
  background-color: #EDEDED;
  font-size: 16px;
  box-shadow: none;
  border-radius: 0;
  height: 40px;
  border: 1px solid #ccc;
}
.casa-wdg .form-control .k-state-focused, .casa-wdg .form-control:focus,
.casa-wdg .casa-form-control .k-state-focused,
.casa-wdg .casa-form-control:focus,
.casa-wdg .k-widget .k-state-focused,
.casa-wdg .k-widget:focus,
.casa-wdg .k-dropdown .k-state-focused,
.casa-wdg .k-dropdown:focus {
  border-color: #C5A563;
  box-shadow: 0 0 7px 0 #C5A563;
}
.casa-wdg .form-control .k-state-focused > .k-select, .casa-wdg .form-control:focus > .k-select,
.casa-wdg .casa-form-control .k-state-focused > .k-select,
.casa-wdg .casa-form-control:focus > .k-select,
.casa-wdg .k-widget .k-state-focused > .k-select,
.casa-wdg .k-widget:focus > .k-select,
.casa-wdg .k-dropdown .k-state-focused > .k-select,
.casa-wdg .k-dropdown:focus > .k-select {
  border-color: #C5A563;
}
.casa-wdg .form-control .k-picker-wrap,
.casa-wdg .form-control .k-state-default,
.casa-wdg .form-control .k-input,
.casa-wdg .casa-form-control .k-picker-wrap,
.casa-wdg .casa-form-control .k-state-default,
.casa-wdg .casa-form-control .k-input,
.casa-wdg .k-widget .k-picker-wrap,
.casa-wdg .k-widget .k-state-default,
.casa-wdg .k-widget .k-input,
.casa-wdg .k-dropdown .k-picker-wrap,
.casa-wdg .k-dropdown .k-state-default,
.casa-wdg .k-dropdown .k-input {
  background-color: #EDEDED;
  border: 0;
  height: 40px;
  line-height: 2.5em;
}
.casa-wdg .form-control .k-picker-wrap .k-state-focused, .casa-wdg .form-control .k-picker-wrap:focus,
.casa-wdg .form-control .k-state-default .k-state-focused,
.casa-wdg .form-control .k-state-default:focus,
.casa-wdg .form-control .k-input .k-state-focused,
.casa-wdg .form-control .k-input:focus,
.casa-wdg .casa-form-control .k-picker-wrap .k-state-focused,
.casa-wdg .casa-form-control .k-picker-wrap:focus,
.casa-wdg .casa-form-control .k-state-default .k-state-focused,
.casa-wdg .casa-form-control .k-state-default:focus,
.casa-wdg .casa-form-control .k-input .k-state-focused,
.casa-wdg .casa-form-control .k-input:focus,
.casa-wdg .k-widget .k-picker-wrap .k-state-focused,
.casa-wdg .k-widget .k-picker-wrap:focus,
.casa-wdg .k-widget .k-state-default .k-state-focused,
.casa-wdg .k-widget .k-state-default:focus,
.casa-wdg .k-widget .k-input .k-state-focused,
.casa-wdg .k-widget .k-input:focus,
.casa-wdg .k-dropdown .k-picker-wrap .k-state-focused,
.casa-wdg .k-dropdown .k-picker-wrap:focus,
.casa-wdg .k-dropdown .k-state-default .k-state-focused,
.casa-wdg .k-dropdown .k-state-default:focus,
.casa-wdg .k-dropdown .k-input .k-state-focused,
.casa-wdg .k-dropdown .k-input:focus {
  border-color: #C5A563;
}
.casa-wdg .form-control .k-input,
.casa-wdg .casa-form-control .k-input,
.casa-wdg .k-widget .k-input,
.casa-wdg .k-dropdown .k-input {
  padding: 0;
}
.casa-wdg .form-control .k-select,
.casa-wdg .casa-form-control .k-select,
.casa-wdg .k-widget .k-select,
.casa-wdg .k-dropdown .k-select {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.casa-wdg .form-control .k-link,
.casa-wdg .casa-form-control .k-link,
.casa-wdg .k-widget .k-link,
.casa-wdg .k-dropdown .k-link {
  height: unset;
}
.casa-wdg .checkbox.compliance label,
.casa-wdg .casa-checkbox label {
  font-size: 1.2rem;
  font-weight: 200;
}
.casa-wdg .form-group .text-right {
  display: flex;
  justify-content: flex-start;
}
@media (min-width: 992px) {
  .casa-wdg .form-group .text-right {
    justify-content: flex-end;
  }
}
.casa-wdg .casa-row .btn-default,
.casa-wdg .casa-row .casa-btn-default,
.casa-wdg .row .btn-default,
.casa-wdg .row .casa-btn-default {
  margin-top: 20px;
  border-radius: 0;
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #C5A563;
  color: #fff;
  border: none;
}
.casa-wdg .casa-row .btn-default:hover,
.casa-wdg .casa-row .casa-btn-default:hover,
.casa-wdg .row .btn-default:hover,
.casa-wdg .row .casa-btn-default:hover {
  background-color: #EDEDED;
  color: #000000;
}
.casa-wdg .casa-row .btn-default:focus,
.casa-wdg .casa-row .casa-btn-default:focus,
.casa-wdg .row .btn-default:focus,
.casa-wdg .row .casa-btn-default:focus {
  outline: 0;
}
.casa-wdg .casa-row .btn-default:focus:active,
.casa-wdg .casa-row .casa-btn-default:focus:active,
.casa-wdg .row .btn-default:focus:active,
.casa-wdg .row .casa-btn-default:focus:active {
  outline: 0;
}

.k-list-scroller .k-list .k-item {
  font-size: 1rem;
}

.infoteaser-items-wrapper {
  position: fixed;
  z-index: 1010;
  display: flex;
  align-items: flex-end;
  pointer-events: none;
}
.infoteaser-items-wrapper.show-it .infoteaser-inner {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.9s ease-in-out;
}
.infoteaser-items-wrapper.hide-it .infoteaser-inner {
  opacity: 0;
  transform: translateX(100vw);
  transition: all 0.9s ease-in-out;
}
.infoteaser-items-wrapper .infoteaser-inner {
  display: flex;
  position: relative;
  bottom: 51px;
  padding: 2rem;
  width: 271px;
  min-height: 271px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  background-color: black;
  pointer-events: auto;
}
.infoteaser-items-wrapper .infoteaser-inner .infoteaser-item p {
  font-family: "Barlow Condensed", sans-serif;
  font-size: 19px;
  font-weight: 400;
  color: white;
}
.infoteaser-items-wrapper .infoteaser-inner .infoteaser-item a {
  font-size: 19px;
}
.infoteaser-items-wrapper .infoteaser-inner .infoteaser-item .infoteaser-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
}
@media (min-width: 0) {
  .infoteaser-items-wrapper {
    left: auto;
    right: 25px;
    bottom: 25px;
    margin-left: 20px;
  }
}
@media (min-width: 992px) {
  .infoteaser-items-wrapper {
    right: 40px;
    bottom: 40px;
  }
}
@media (min-width: 1600px) {
  .infoteaser-items-wrapper .infoteaser-inner {
    bottom: unset;
  }
}

.infoteaser-close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 26px;
  height: 26px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  margin-top: 0;
}
.infoteaser-close:focus {
  box-shadow: unset;
}
.infoteaser-close::before, .infoteaser-close::after {
  content: "";
  position: absolute;
  left: 12px;
  top: 0;
  height: 26px;
  width: 1px;
  background-color: white;
}
.infoteaser-close::before {
  transform: rotate(45deg);
}
.infoteaser-close::after {
  transform: rotate(-45deg);
}

.swatch-primary {
  color: #212529;
  background-color: #C5A563;
}

.swatch-secondary {
  color: #fff;
  background-color: #000000;
}

.swatch-success {
  color: #fff;
  background-color: #28a745;
}

.swatch-danger {
  color: #fff;
  background-color: #ff0000;
}

.swatch-light {
  color: #212529;
  background-color: #EDEDED;
}

.swatch-dark {
  color: #fff;
  background-color: #000000;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1070;
}

.container {
  padding-left: 25px;
  padding-right: 25px;
}
@media (min-width: 768px) {
  .container {
    width: 90%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 80%;
  }
}
.container .container {
  padding-left: 0;
  padding-right: 0;
}

.style-wave::after {
  content: "";
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Icons/welle.svg");
  width: 58px;
  height: 30px;
  display: block;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 10px 0 20px;
}
.style-wave.text-center {
  display: block;
}
.style-wave.text-center::after {
  margin: 10px auto 20px;
}

main ul {
  list-style-type: none;
  padding-left: 25px;
}
main ul li {
  position: relative;
  padding: 7px 0;
  color: #000000;
  font-size: 1.375rem;
  line-height: 1.3;
}
main ul li:first-child:not(.splide__slide) {
  padding-top: 0;
}
main ul li:not(.splide__slide)::before {
  content: "";
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/Freigold_Logo_i-nochbreiter.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  left: -25px;
  width: 7px;
  height: 20px;
  margin-top: 7px;
}
main ul li::marker {
  display: none;
}

.footer a {
  text-decoration: none;
}
.footer a:hover {
  text-decoration: underline;
}
.footer-content {
  color: #fff;
  background-color: #000000;
  font-size: 1.0625rem;
  padding: 60px 0;
}
.footer-content a {
  color: #fff;
}
@media (min-width: 768px) {
  .footer-content {
    padding: 80px 0;
  }
}
@media (min-width: 992px) {
  .footer-content {
    font-size: 1.0625rem;
    text-transform: none;
    padding: 110px 0;
  }
}
.footer-content .footer-logo img {
  margin: 0 auto;
  display: block;
  max-width: 100px;
  width: 100%;
}
@media (min-width: 992px) {
  .footer-content .footer-logo img {
    max-width: 155px;
  }
}
@media (min-width: 576px) {
  .footer-content .footer-logo img {
    margin: 0 auto 0 0;
  }
}
.footer-content .footer-address {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footer-content .footer-address address p {
  text-align: center;
  color: #fff;
  font-size: 1.2rem;
}
@media (min-width: 576px) {
  .footer-content .footer-address address p {
    text-align: left;
    font-size: 1.2rem;
  }
}
.footer-content .footer-address .social {
  margin-top: 20px;
}
@media (min-width: 576px) {
  .footer-content .footer-address .social {
    margin-left: 0;
    margin-right: 0;
  }
}
.footer-content .footer-address .social ul {
  display: flex;
  justify-content: center;
}
@media (min-width: 576px) {
  .footer-content .footer-address .social ul {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
.footer-content .footer-address .social .list-inline-item:not(:last-child) {
  margin-right: 2rem;
  margin-bottom: 1rem;
}
@media (min-width: 992px) {
  .footer-content .footer-address .social .list-inline-item:not(:last-child) {
    margin-right: 1rem;
  }
}
@media (min-width: 1200px) {
  .footer-content .footer-address .social .list-inline-item:not(:last-child) {
    margin-right: 2rem;
  }
}
.footer-content .footer-address .social a, .footer-content .footer-address .social svg {
  width: 20px;
  height: 20px;
  display: block;
}
.footer-content .footer-auszeichnungen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footer-content .footer-auszeichnungen .item {
  margin: 3rem 1rem 0;
}
.footer-content .footer-auszeichnungen .item img {
  display: block;
  height: 70px;
}
@media (min-width: 768px) {
  .footer-content .footer-auszeichnungen .item {
    margin-left: 0;
    margin-right: 2rem;
  }
}
@media (min-width: 992px) {
  .footer-content .footer-auszeichnungen {
    flex-direction: column;
  }
  .footer-content .footer-auszeichnungen .item {
    margin: 0 0 1rem 0;
  }
}
@media (min-width: 1600px) {
  .footer-content .footer-auszeichnungen {
    flex-direction: row;
    align-items: flex-start;
  }
  .footer-content .footer-auszeichnungen .item img {
    height: 90px;
  }
}
.footer-content .footer-meta-navigation div {
  height: 100%;
}
.footer-content .footer-meta-navigation div nav {
  height: 100%;
}
.footer-content .footer-meta-navigation div nav .list-inline {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.footer-content .footer-meta-navigation div nav .list-inline .list-inline-item {
  margin: 0;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.6;
}
@media (min-width: 576px) {
  .footer-content .footer-meta-navigation div nav .list-inline {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .footer-content .footer-meta-navigation div nav .list-inline .list-inline-item {
    margin: 0 10px;
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .footer-content .footer-meta-navigation div nav .list-inline {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 0 10px;
  }
  .footer-content .footer-meta-navigation div nav .list-inline .list-inline-item {
    margin: 0;
    line-height: 1.3;
    padding-bottom: 7px;
  }
}
@media (min-width: 576px) {
  .footer-content .footer-meta-navigation {
    margin-top: 60px;
  }
}
@media (min-width: 768px) {
  .footer-content .footer-meta-navigation {
    margin-top: 0;
  }
}
.footer-bottom {
  background-color: #EDEDED;
  font-size: 1rem;
}
.footer-bottom p {
  margin: 0;
}
@media (min-width: 992px) {
  .footer-bottom {
    font-size: 1rem;
  }
}
.footer-bottom .list-inline {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .footer-bottom .list-inline {
    justify-content: flex-end;
  }
}
.footer-bottom .list-inline-item {
  margin-bottom: 10px;
  font-size: 1.1rem;
}
@media (min-width: 992px) {
  .footer-bottom .list-inline-item {
    margin-bottom: 0;
  }
}
.footer-bottom .list-inline-item a {
  color: #000000;
}
.footer-bottom .list-inline-item:not(:last-child) {
  margin-right: 15px;
}
@media (min-width: 992px) {
  .footer-bottom .list-inline-item:not(:last-child) {
    margin-right: 25px;
  }
}
@media (min-width: 1200px) {
  .footer-bottom .list-inline-item:not(:last-child) {
    margin-right: 40px;
  }
}

.scroll-top {
  opacity: 0;
  z-index: 1050;
  background-color: #C5A563;
  color: #fff;
  bottom: -1px;
  left: -1px;
  position: fixed;
  display: none;
  transform: translateX(-100vw);
  transition: all 0.9s ease-in-out;
  font-size: initial;
}
.scroll-top a {
  text-decoration: none;
}
@media (min-width: 1600px) {
  .scroll-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-family: "Ostrich Sans Inline", sans-serif;
  }
  .scroll-top .langswitcher {
    height: 51px;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
  }
  .scroll-top .langswitcher .language-menu .langnav-item:not(.current) {
    width: 0;
    opacity: 0;
  }
  .scroll-top .langswitcher .language-menu .langnav-item a {
    font-size: 1.1875rem;
    text-transform: lowercase;
    display: flex;
    color: #fff;
  }
  .scroll-top .langswitcher .language-menu .langnav-item a span {
    width: 0;
    opacity: 0;
    margin: 0;
  }
  .scroll-top .mobile-icon-buttons {
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 12px;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link,
.scroll-top .mobile-icon-buttons .w4-booking-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: slideInLeft 400ms ease-in-out forwards;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link .icon,
.scroll-top .mobile-icon-buttons .w4-booking-link .icon {
    fill: #fff;
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link .text,
.scroll-top .mobile-icon-buttons .w4-booking-link .text {
    font-size: 1.1875rem;
    margin-top: 10px;
    text-transform: none;
    line-height: 1;
    color: #fff;
    font-weight: 400;
    font-family: "Barlow Condensed", sans-serif;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link:hover .icon, .scroll-top .mobile-icon-buttons .w4-mobile-menu-link:focus .icon,
.scroll-top .mobile-icon-buttons .w4-booking-link:hover .icon,
.scroll-top .mobile-icon-buttons .w4-booking-link:focus .icon {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link:hover .text, .scroll-top .mobile-icon-buttons .w4-mobile-menu-link:focus .text,
.scroll-top .mobile-icon-buttons .w4-booking-link:hover .text,
.scroll-top .mobile-icon-buttons .w4-booking-link:focus .text {
    text-decoration: none;
  }
  .scroll-top .mobile-icon-buttons .w4-booking-link {
    margin: 0;
  }
  .scroll-top .mobile-icon-buttons .w4-booking-link .icon {
    display: block;
  }
  .scroll-top .mobile-icon-buttons .w4-phone-link {
    animation-delay: 200ms;
  }
  .scroll-top .mobile-icon-buttons .w4-voucher-link {
    animation-delay: 400ms;
    margin: 0 !important;
  }
  .scroll-top .mobile-icon-buttons .w4-faq-link {
    animation-delay: 600ms;
  }
  .scroll-top .mobile-icon-buttons .w4-mobile-menu-link .text {
    display: block;
  }
  .scroll-top .mobile-icon-buttons .line {
    background-color: #fff;
    opacity: 40%;
    height: 1px;
    width: 70px;
    display: block;
    animation: fadeIn 1.5s ease-in-out forwards;
    margin: 20px auto;
  }
}
.scroll-top .scroll-top-link {
  overflow: hidden;
  width: 3em;
  height: 3em;
  display: block;
  position: relative;
  color: #fff;
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}
.scroll-top .scroll-top-link:active, .scroll-top .scroll-top-link:focus, .scroll-top .scroll-top-link:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease-in-out;
}
.scroll-top.scroll-top-visible {
  transform: translateX(0);
  opacity: 1;
  transition: all 0.9s ease-in-out;
}

.css-arrow, .scroll-top .scroll-top-link .scroll-top-icon {
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.css-arrow:before, .scroll-top .scroll-top-link .scroll-top-icon:before {
  content: "";
  position: absolute;
  height: 30%;
  width: 30%;
  border-top: 1px solid;
  border-left: 1px solid;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.banner {
  position: relative;
}
.banner .image {
  margin: 0;
}
.banner .image img {
  filter: brightness(0.7);
}
.banner .image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner .image-caption .element-header {
  display: block;
  color: #fff;
}
.banner .image-caption .banner-text {
  font-weight: 400;
}
.banner .image-caption .banner-text .btn-primary {
  font-weight: 400;
}
.banner .image-caption p {
  color: #fff;
}

/* Tabellen auf Seite "Kosmetik & Masagen - ID 207" anpassen, dass die zweite Spalte mit den Preisen gleich breit ist */
.page-207 table tbody tr td:nth-of-type(2) {
  width: 20%;
}

.sub-header {
  font-size: 1.4rem;
  display: block;
}
@media (min-width: 768px) {
  .sub-header {
    font-size: 1.6rem;
  }
}
@media (min-width: 992px) {
  .sub-header {
    font-size: 1.8rem;
  }
}

h1.element-header {
  color: #C5A563;
}