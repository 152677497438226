// Textpic and Textmedia

.textmedia,
.textpic {
  display: flex;
  flex-wrap: wrap;
}

.textmedia-item,
.textpic-item {
  width: 100%;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;


  figure {
    margin-bottom: 0;

    .embed {
      display: flex;
    }
  }

  video, img {
    width: 100%;
  }
}

.textpic-item {
  order: 1;
}


.textmedia-text,
.textpic-text {
  > *:last-child {
    margin-bottom: 0;
  }
}

.textmedia-gallery,
.textpic-gallery {
  margin-bottom: 25px;

  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.textpic-left {
  .textpic-gallery {
    order: 2;

    @include media-breakpoint-up(md) {
      order: 1;
    }
  }
}

// - Alignments

.textmedia-below,
.textpic-below {
  .textmedia-text,
  .textpic-text {
    //order: 0;
    margin-bottom: $spacer;
  }

  .textmedia-gallery,
  .textpic-gallery {
    margin-bottom: 0;
  }
}

@include media-breakpoint-up(md) {
  .textmedia-right,
  .textmedia-left,
  .textpic-right,
  .textpic-left {
    .textmedia-item,
    .textpic-item {
      margin-bottom: 0;
      width: 50%;
    }
  }
}

.frame-type-textpic {
  .figure-caption {
    display: none;
  }

  &:not(.frame-traunreiter) {
    .textpic {
      .textpic-text {
        padding: 40px 0;

        @include media-breakpoint-up(xl) {
          padding: 100px 0;
        }
      }

      .textpic-gallery {
        margin-bottom: 0;

        .image {
          margin-right: calc(-50vw + 50%);
          margin-left: calc(-50vw + 50%);
          margin-bottom: 0;

          .figure-img {
            width: 100%;
          }
        }
      }

      @include media-breakpoint-up(md) {
        justify-content: space-between;

        &.textpic-right {
          .image {
            margin-left: 0;
            margin-right: calc(-50vw + 100%);
          }
        }

        &.textpic-left {
          .image {
            margin-right: 0;
            margin-left: calc(-50vw + 100%);
          }
        }

        .textpic-text {
          max-width: 520px;
        }

        .textpic-gallery {
          display: flex;

          .gallery-row {
            flex-grow: 1;

            .gallery-item {
              display: flex;
              flex-grow: 1;
              padding: 0;

              .image {
                flex-grow: 1;
                display: flex;
                margin-bottom: 0;

                picture {
                  position: relative;
                  flex-grow: 1;

                  .figure-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
            }
          }

          .figure-caption {
            display: none;
          }

        }
      }
    }
  }
}

.textmedia-stoerer-left,
.textmedia-stoerer-right {
  position: relative;

  .textmedia-gallery {
    justify-content: flex-start;
  }

  @include media-breakpoint-down(md) {
    figure.image img {
      margin-top: 50px;
      max-width: 220px;
    }
  }
}

.textmedia-stoerer-left {
  &:after {
    content: '';
    position: absolute;
    top: 7%;
    bottom: -10%;
    width: 110%;
    background-color: $gray-100;
    z-index: -1;

    @include media-breakpoint-up(md) {
      width: 150%;
      top: 7%;
      bottom: -10%;
      left: 0;
      right: initial;
    }
  }
}

.textmedia-stoerer-right {
  &:after {
    content: '';
    position: absolute;
    top: 7%;
    bottom: -10%;
    left: -25px;
    right: -25px;
    width: 110%;
    background-color: $gray-100;
    z-index: -1;

    @include media-breakpoint-up(md) {
      width: 150%;
      top: 7%;
      bottom: -10%;
      right: 0;
      left: initial;
    }
  }
}


.textmedia-offgrid-layout {
  position: relative;

  .header-title {
    @include media-breakpoint-up(md) {
      position: absolute;
      left: -30px;
      bottom: 25%;
      transform: rotate(-90deg);
      transform-origin: left;
      z-index: 1000;
    }

    @include media-breakpoint-up(lg) {
      left: 0;
    }
  }
}

.textmedia-offgrid-layout-left {
  .textmedia-gallery {
    justify-content: flex-start;
  }

  .header-title {
    top: 0;
    left: 0;
    transform-origin: left;
  }
}

.textpic-offgrid {
  position: relative;

  &::after {
    display: none;
    position: absolute;
    content: '';
    background-color: $gold;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 50%;
    z-index: -1;

    @include media-breakpoint-up(sm) {
      display: initial;
    }

    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }

  &::before {
    display: none;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    z-index: 0;
    background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern.svg");
    background-repeat: repeat;
    background-size: contain;
    clip-path: inset(0 50% 0 0);

    @include media-breakpoint-up(sm) {
      display: initial;
    }

    @include media-breakpoint-up(lg) {
      height: 100%;
    }
  }


  .header-title {
      position: absolute;
      bottom: 0;
      z-index: 1000;
      top: 55%;

    .element-header {
      color: white;
      text-align: center;
    }
  }

  .gallery-item {
    padding-top: 40px;

    @include media-breakpoint-up(sm) {
      padding-top: 0;
    }
  }

  figure.image {
    position: relative;
  }

  figure.image::after {
    display: initial;
    position: absolute;
    content: '';
    background-color: $gold;
    top: -40px;
    width: 80%;
    height: calc(100% + 80px);
    z-index: -2;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  figure.image::before {
    display: initial;
    position: absolute;
    content: '';
    top: -40px;
    width: 80%;
    height: calc(100% + 80px);
    z-index: -1;
    left: initial;
    right: -25px;
    background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern.svg");
    background-repeat: repeat;
    background-size: contain;

    @include media-breakpoint-up(sm) {
      display: none;
    }
  }

  figure.image picture {
    background: black;
    margin-bottom: 50px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  figure.image img {
    opacity: 0.8;
    margin: 0;
    width: 100%;
    height: auto;
  }

  &.textpic-offgrid-text-right {
    left: 0;

    .header-title {
      left: 0;
      transform: translateX(25%) translateY(-50%);

      @include media-breakpoint-up(lg) {
        transform: translateX(-33%) translateY(-50%);
      }

      @include media-breakpoint-up(xl) {
        transform: translateX(-50%) translateY(-50%);
      }
    }

    figure.image::after {
      left: -25px;
    }

    figure.image::before {
      left: -25px;
      right: initial;
    }


    figure.image {
      padding-right: 50px;
    }
  }

  &.textpic-offgrid-text-left {
    .header-title {
      right: 0;
      transform: translateX(-25%) translateY(-50%);

      @include media-breakpoint-up(lg) {
        transform: translateX(33%) translateY(-50%);
      }

      @include media-breakpoint-up(xl) {
        transform: translateX(50%) translateY(-50%);
      }
    }

    &::after,
    &::before {
      right: 0;
    }

    &::before {
      clip-path: inset(0 0 0 50%);
    }

    figure.image::after {
      right: -25px;
    }

    figure.image {
      padding-left: 50px;
    }
  }

  .textpic-offgrid-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }


}

@include media-breakpoint-down(sm) {
  .textpic-offgrid.frame.frame-space-after-large,
  .textpic-offgrid.frame.frame-space-before-large {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.frame-textmedia-offgrid-layout {
  &::after {
    position: absolute;
    content: '';
    background-color: white;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 20%;
    z-index: 1;
  }

  .textmedia-item {
    position: relative;
    z-index: 2;
  }
}
