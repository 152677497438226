// Texticon

.texticon {
    @include clearfix();

    &-above {
        .texticon-icon {
            text-align: center;
            margin-bottom: 1rem;
        }
    }

    &-left,
    &-right {
        .texticon-icon {
            position: relative;
            min-height: 1px;
            padding-left:  ($grid-gutter-width / 2);
            padding-right: ($grid-gutter-width / 2);
        }

        .texticon-content {
            *:first-child {
                margin-top: 0;
            }
            *:last-child {
                margin-bottom: 0;
            }
            overflow: hidden;
            zoom: 1;
        }
    }

    &-left {
        .texticon-icon {
            float: left;
            margin-left: -1 * ($grid-gutter-width / 2);
        }
    }

    &-right {
        .texticon-icon {
            float: right;
            margin-right: -1 * ($grid-gutter-width / 2);
        }
    }
}
