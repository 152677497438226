// Uploads
@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import "bootstrap/scss/media";

.media-list {
    @include list-unstyled;

    .media + .media {
        margin-top: 0.5rem;
    }
}

.media {
    &-left {
        padding-right: 0.5rem;
    }

    &-heading {
        display: flex;
        align-items: center;

        > a {
            display: inline-flex;
            align-items: center;
        }
    }
}

.uploads {
    &-fileicon {
        margin-right: 0.25rem;

        svg {
            width: 1em;
            height: 1em;
        }
    }

    &-filesize {
        margin-left: 0.25rem;
    }
}
