/* Splide Core Files */
@import '@splidejs/splide/src/sass/core/foundation/variables';
@import '@splidejs/splide/src/sass/core/foundation/animations';

@import '@splidejs/splide/src/sass/core/object/objects/root';
@import '@splidejs/splide/src/sass/core/object/objects/container';
@import '@splidejs/splide/src/sass/core/object/objects/list';
@import '@splidejs/splide/src/sass/core/object/objects/pagination';
@import '@splidejs/splide/src/sass/core/object/objects/slide';
@import '@splidejs/splide/src/sass/core/object/objects/slider';
//@import '@splidejs/splide/src/sass/core/object/objects/spinner';
@import '@splidejs/splide/src/sass/core/object/objects/track';

@import '@splidejs/splide/src/sass/core/object/modifiers/draggable';
@import '@splidejs/splide/src/sass/core/object/modifiers/fade';
//@import '@splidejs/splide/src/sass/core/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/core/object/modifiers/ttb';

/* Splide Default Style Files */
@import '@splidejs/splide/src/sass/themes/default/foundation/variables';

//@import '@splidejs/splide/src/sass/themes/default/object/objects/arrow'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/pagination'; --> custom
//@import '@splidejs/splide/src/sass/themes/default/object/objects/progress';
@import '@splidejs/splide/src/sass/themes/default/object/modifiers/nav';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/rtl';
//@import '@splidejs/splide/src/sass/themes/default/object/modifiers/ttb';


.type-wvierteaser-teaserslider {
    .element-header {
        margin-bottom: 20px;
    }

    overflow: hidden;
    .splide {
        &__track {
            overflow: unset;

        }
    }
}

.splide {
    visibility: unset;

    &__slide {
        &:after {
            display: none;
        }
    }

    &__track {
        overflow: visible;

        .image {
            overflow: hidden;

            img {
                width: 100%;
                transform: scale(1);
                transition: transform $transition-time $transition-ease;
            }

            .figure-caption {
                display: none;
            }

            &:hover,
            &:focus {
                img {
                    transform: scale(1.05);
                    transition: transform $transition-time $transition-ease;
                }
            }
        }
    }

    &__arrow {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border: 0;
        padding: 0;
        background: transparent;
        transition: fill $transition-time $transition-ease;

        svg {
            width: 25px;
            height: 25px;

            @include media-breakpoint-up (lg) {
                width: 40px;
                height: 40px;
            }
        }

        @include media-breakpoint-up (lg) {
            width: 40px;
            height: 40px;
        }

        &--next {
            transform: rotate(180deg);
        }

        &[disabled] {
            fill: $lightgray;
        }

        &:hover {
            cursor: pointer;

            &:not(:disabled) {
                fill: $primary;
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.image-splide.mfp-gallery {
    .splide__slide.is-active + .splide__slide{
        transform: translateY(50px);

        @include media-breakpoint-up (md) {
            transform: translateY(75px);
        }

        @include media-breakpoint-up (lg) {
            transform: translateY(100px);
        }
    }

    .splide__slide {
        transition: all $transition-time $transition-ease;
        //transform: scale(0.8);
    }

    .splide__arrows {
        padding-top: 100px;
    }
}

.frame-type-wvier_imageslider {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/bg-pattern-gallery.svg");
        background-size: contain;
        background-repeat: no-repeat;
        left: 25%;
        top: 25%;
        height: 80%;
        width: 50%;
        z-index: -1;



        @include media-breakpoint-up (sm) {
            max-width: 520px;
            left: 33%;
            top: 10%;
            bottom: -10%;
        }
    }
}
